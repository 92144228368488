.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: fit-content;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  display: flex;
  padding: 5px 18px 5px 0;
  align-items: center;
}

.select-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.deleted {
  background-color: rgba(250, 186, 186, 0.53);
  border-radius: 5px;
}